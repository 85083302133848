import React, { useState } from "react";
import { useHistory } from "react-router-dom";
import logo from "../images/header-logo-white.png";
import TextField from "@mui/material/TextField";
import Grid from "@mui/material/Grid";
import Button from "@mui/material/Button";
import Snackbar from "@mui/material/Snackbar";
import MuiAlert from "@mui/material/Alert";
import axios from "axios";
import { APIURL } from "../apiUri";
const Alert = React.forwardRef(function Alert(props, ref) {
  return <MuiAlert elevation={6} ref={ref} variant="filled" {...props} />;
});
const Login = () => {
  const navigate = useHistory();
  const [user, setUser] = useState({
    Email: "",
    Password: ""
  });
  const [userLoginData, setUserLoginData] = useState([]);
  // const [userLoginData, setUserLoginData] = useState([]);
  const [state, setState] = useState({
    open: false,
    vertical: "top",
    horizontal: "center",
    alertMessage: "",
    alertType: "warning",
  });
  const changeUser = (e, value) => {
    setUser({ ...user, [value]: e.target.value });
  };
  const handleClick = (message, type) => {
    setState({ ...state, open: true, alertMessage: message, alertType: type });
  };

  const handleClose = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }
    setState({ ...state, open: false });
  };

  const onSubmit = () => {
    if (user.Email === null || user.Email === "") {
      handleClick("Please enter a Email", "warning");
    } else if (user.Password === null || user.Password === "") {
      handleClick("Please enter a Password", "warning");
    } else {
      axios.post(APIURL + "Admin/GetUserLogin", user).then((response) => {
        if (response.status === 200) {
          if (response.data.length == 0) {
            handleClick(
              "Please enter a correct email and password.",
              "warning"
            );
          } else {
            setUserLoginData(response.data[0]);
            setUser({ ...user, Email: response.data[0].email });
            localStorage.setItem("userRole", response.data[0]);
            localStorage.setItem("user", response.data[0]);
            var propsData = { id: 0, data: response.data[0] };
            // if (response.data[0].roleName != "Admin") {
            switch (response.data[0].menuName.split(",")[0]) {
              case "Shipment Details Report":
                navigate.push("/shipmentdetailsreport", response.data[0]);
                break;
                case "ChainAlytic Report":
                navigate.push("/chainalyticreport", response.data[0]);
                break;  
                case "Turvo Invoice Report":
                navigate.push("/turvoinvoicereport", response.data[0]);
                break;   
                case "Good Foods Report":
                navigate.push("/goodfoodsreport", response.data[0]);
                break;               
              default: 
              navigate.push("/shipmentdetailsreport", response.data[0]);
                break;
            }
            // } else {
            //   navigate.push("/admin", propsData);
            // }
          }
        }
      });
    }
  };
  return (
    <div className="bg-color">
      <div className="bg-color-upper">
        <div className="pt-login">
          <div className="w-50">
            <Grid container>
              <Grid item xs={2}></Grid>
              <Grid item xs={8}>
                <div className="border-login-inner-div">
                <Grid item xs={12}>
                <div className="text-center pb-5">
          <img src={logo} loading="lazy" className="" width="250" />
        </div>
                </Grid>
                <Grid item xs={12} className="mt-4">
                  <TextField
                    required
                    autoComplete="off"
                    id="outlined-required"
                    className="w-100 input-div input-div-email login-text"
                    placeholder="Email"
                    onChange={(e) => changeUser(e, "Email")}
                  />
                </Grid>
                <Grid item xs={12} className="mt-5">
                  <TextField
                    required
                    autoComplete="new-password"
                    type="password"
                    id="outlined-required"
                    className="w-100 input-div input-div-pwd login-text"
                    placeholder="Password"
                    onChange={(e) => changeUser(e, "Password")}
                  />
                </Grid>
                <Grid item xs={12} className="text-center mt-4 pt-5">
                  <Button
                    className="btn-submit-contained pl-5 pr-5 pt-2 pb-2 input-div-btn "
                    onClick={onSubmit}
                  >
                    Login
                  </Button>
                </Grid>
                </div>
              </Grid>
              <Grid item xs={2}></Grid>
            </Grid>
          </div>
        </div>
        <Snackbar
          open={state.open}
          autoHideDuration={3000}
          onClose={handleClose}
          anchorOrigin={{
            vertical: state.vertical,
            horizontal: state.horizontal,
          }}
        >
          <Alert
            onClose={handleClose}
            severity={state.alertType}
            sx={{ width: "100%" }}
          >
            {state.alertMessage}
          </Alert>
        </Snackbar>
      </div>
    </div>
  );
};
export default Login;
