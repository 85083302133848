import './App.css';
import 'bootstrap/dist/css/bootstrap.min.css';
// Browser Router
import { BrowserRouter as Router, Switch, Route } from "react-router-dom";
import { useHistory } from "react-router-dom";
import Login from './components/login';
import Dashboard from './components/user/dashboard';
import UserSideDrawer from './components/user/sidebarUser';
import Admin from './components/admin/admin';
function App() {
  const navigate = useHistory();
  if( localStorage.getItem('user') === "null" )
  {
    navigate.push("/");
  }      
window.scrollTo(0, 0);

return (

    <Router>
      <Switch> 
        <Route path="/dashboard" >
          <Dashboard />
        </Route>
        <Route path="/shipmentdetailsreport">
          <UserSideDrawer />
        </Route>  
        <Route path="/chainalyticreport">
          <UserSideDrawer />
        </Route>  
        <Route path="/turvoinvoicereport">
          <UserSideDrawer />
        </Route>  
        <Route path="/goodfoodsreport">
          <UserSideDrawer />
        </Route>  
        <Route path="/admin">
          <Admin />
        </Route> 
        <Route path="/user">
          <Admin />
        </Route> 
        <Route path="/adduser">
          <Admin />
        </Route> 
        <Route path="/reports">
          <Admin />
        </Route> 
        <Route path="/addreport">
          <Admin />
        </Route> 
        <Route path="/">
          <Login />
        </Route>  
      </Switch>
    </Router>
  );
}

export default App;
