import React, { useEffect, useState } from "react";
import "font-awesome/css/font-awesome.min.css";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell, { tableCellClasses } from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import Paper from "@mui/material/Paper";
import { useHistory, useLocation } from "react-router-dom";
import AddIcon from "@mui/icons-material/Add";
import EditIcon from "@mui/icons-material/Edit";
import { styled } from "@mui/material/styles";
import Divider from "@mui/material/Divider";
import Grid from "@mui/material/Grid";
import axios from "axios";
import { APIURL } from "../../apiUri";
import DeleteIcon from "@mui/icons-material/Delete";
import Snackbar from "@mui/material/Snackbar";
import MuiAlert from "@mui/material/Alert";
import LoaderGIF from "../../images/loader.gif";
import PropTypes from 'prop-types';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import ListItemText from '@mui/material/ListItemText';
import DialogTitle from '@mui/material/DialogTitle';
import DialogContent from '@mui/material/DialogContent';
import DialogActions from '@mui/material/DialogActions';
import Dialog from '@mui/material/Dialog';
import RadioGroup from '@mui/material/RadioGroup';
import Radio from '@mui/material/Radio';
import FormControlLabel from '@mui/material/FormControlLabel';


const Alert = React.forwardRef(function Alert(props, ref) {
  return <MuiAlert elevation={6} ref={ref} variant="filled" {...props} />;
});
const StyledTableCell = styled(TableCell)(({ theme }) => ({
  [`&.${tableCellClasses.head}`]: {
    backgroundColor: "#1f1f1f",
    color: theme.palette.common.white,
  },
  [`&.${tableCellClasses.body}`]: {
    fontSize: 14,
  },
}));

const StyledTableRow = styled(TableRow)(({ theme }) => ({
  "&:nth-of-type(odd)": {
    backgroundColor: theme.palette.action.hover,
  },
  // hide last border
  "&:last-child td, &:last-child th": {
    border: 0,
  },
}));

const UserList = () => {
  const navigate = useHistory();
  const location = useLocation();
  const [props, setProps] = useState(location.state);
  const [isLoading, setIsLoading] = useState(false);
  const [deleteId, setToDelete] = useState(0);
  
  const [users, setUsers] = useState([]);
  const goToBack = (value) => {
    location.state.id = 0;
    navigate.push("/" + value, location.state);
  };
  const goToEdit = (id) => {
    location.state.id = id;
    navigate.push("/adduser", location.state);
  };
  const handleDelete =(id)=>{
    setToDelete(id);
    setOpen(!open);
  }

  const [state, setState] = useState({
    open: false,
    vertical: "top",
    horizontal: "center",
    alertMessage: "",
    alertType: "warning",
  });
  const handleClick = (message, type) => {
    setState({ ...state, open: true, alertMessage: message, alertType: type });
  };
  const handleCloseAlert = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }
    setState({ ...state, open: false });
  };
  const getData = () => {
    axios.get(APIURL + "Admin/GetUser").then((response) => {
      setUsers(response.data);
      setIsLoading(false);
    });
  };

  useEffect(() => {
    setIsLoading(true);
    getData();
  }, []);

  const goToDelete = (id) => {
    setIsLoading(true);
    axios.post(APIURL + "Admin/DeleteUser?Id=" + id).then((response) => {
      if (response.status === 200) {
        handleClick("deleted successfully", "success");  
        setOpen(false);
        getData();
      }
    });
  };  
  const [open, setOpen] = React.useState(false);
  const [value, setValue] = React.useState('Dione');

  const handleClickListItem = () => {
    setOpen(true);
  };

  const handleClose = (newValue) => {
    setOpen(false);

    if (newValue) {
      setValue(newValue);
    }
  };
  return isLoading ? (
    <div>
      <img alt="" className="loader-css"
                     src={LoaderGIF}
                     loading="lazy" 
                    /> 
    </div>
   ) : (
    <div className="h-admin">
      <div className="m-5">
        <div className="text-right mb-3">
          <Grid container>
            <Grid item xs={2} className="text-left">
              <label className="color-000 mb-0 font-label">Users</label>
            </Grid>
            <Grid item xs={8}></Grid>
            <Grid item xs={2}>
              <button
                type="submit"
                className="btn  btn-submit "
                onClick={() => {
                  goToBack("adduser");
                }}
              >
                Add User
              </button>
            </Grid>
          </Grid>
        </div>
        <Divider className="mb-3" />
        <TableContainer component={Paper}>
          <Table sx={{ minWidth: 700 }} aria-label="customized table">
            <TableHead>
              <TableRow>
                <StyledTableCell>First Name</StyledTableCell>
                <StyledTableCell align="right">Last Name</StyledTableCell>
                <StyledTableCell align="right">Email</StyledTableCell>
                <StyledTableCell align="right">Password</StyledTableCell>
                <StyledTableCell align="right">Reports</StyledTableCell>
                <StyledTableCell align="right">Admin</StyledTableCell>
                <StyledTableCell align="right">Action</StyledTableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {users.map((row) => (
                <StyledTableRow key={row.userId}>
                  <StyledTableCell component="th" scope="row">
                    {row.firstName}
                  </StyledTableCell>
                  <StyledTableCell align="right">
                    {row.lastName}
                  </StyledTableCell>
                  <StyledTableCell align="right">{row.email}</StyledTableCell>
                  <StyledTableCell align="right">{row.password}</StyledTableCell>
                  <StyledTableCell align="right">
                    {row.menuList}
                  </StyledTableCell>
                  <StyledTableCell align="right">
                    {row.isAdmin ? "Yes" : "No"}
                  </StyledTableCell>
                  <StyledTableCell align="right">
                    <EditIcon
                      className="btn-icon"
                      onClick={() => {
                        goToEdit(row.userId, "adduser");
                      }}
                    />
                    <DeleteIcon
                      className="btn-icon ml-3"
                      onClick={() => {
                        handleDelete(row.userId);
                      }} 
                    />
                  </StyledTableCell>
                </StyledTableRow>
              ))}
            </TableBody>
          </Table>
        </TableContainer>
      </div>
      <Snackbar
        open={state.open}
        autoHideDuration={3000}
        onClose={handleCloseAlert}
        anchorOrigin={{
          vertical: state.vertical,
          horizontal: state.horizontal,
        }}
      >
        <Alert
          onClose={handleCloseAlert}
          severity={state.alertType}
          sx={{ width: "100%" }}
        >
          {state.alertMessage}
        </Alert>
      </Snackbar> 
        <Dialog
        sx={{ '& .MuiDialog-paper': { width: '80%', maxHeight: 435 } }}
        maxWidth="xs"
        open={open}
      >
        <DialogContent dividers>
          <div className="p-5"><h5>Are you sure you want to delete?</h5></div>
        </DialogContent>
        <DialogActions>
          <Button autoFocus onClick={handleClose}>
            Cancel
          </Button>
          <Button className="btn  btn-submit"   onClick={() => {
                        goToDelete(deleteId);
                      }}>Ok</Button>
        </DialogActions>
      </Dialog>
    </div>
  );
};
export default UserList;
