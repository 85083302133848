import React, { useEffect, useState } from "react";
import "font-awesome/css/font-awesome.min.css";
import { useHistory, useLocation } from "react-router-dom";
import Grid from "@mui/material/Grid";
import TextField from "@mui/material/TextField";
import axios from "axios";
import { APIURL } from "../../apiUri";
import Snackbar from "@mui/material/Snackbar";
import MuiAlert from "@mui/material/Alert";
import LoaderGIF from "../../images/loader.gif";
const Alert = React.forwardRef(function Alert(props, ref) {
  return <MuiAlert elevation={6} ref={ref} variant="filled" {...props} />;
});
const MenuAdd = () => {
  const location = useLocation();
  const [props, setProps] = useState(location.state);
  const [isLoading, setIsLoading] = useState(false);
  const navigate = useHistory();
  const data = null;
  const [menuSave, setMenuSave] = useState({
    Name: "",
    CreatedAt: new Date(),
    UpdatedAt: new Date(),
  });
  const [menuData, setMenuData] = useState(null);
  const goToBack = (value) => {
    navigate.push(value, location.state);
  };

  const changeMenu = (e, value) => {
    setMenuSave({ ...menuSave, [value]: e.target.value });
  };

  useEffect(() => {
    setIsLoading(true);
    if (props.id != 0) {
      axios
        .get(APIURL + "Admin/GetMenuById?Id=" + props.id)
        .then((response) => setMenuData(response.data[0]));
        
    setIsLoading(false);
    }
  }, []);

  useEffect(() => {
    if (menuData != null) {
      setMenuSave({ ...menuSave, Name: menuData.name });
    }
  }, [menuData]);

  const [state, setState] = useState({
    open: false,
    vertical: "top",
    horizontal: "center",
    alertMessage: "",
    alertType: "warning",
  });
  const handleClick = (message, type) => {
    setState({ ...state, open: true, alertMessage: message, alertType: type });
  };
  const handleClose = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }
    setState({ ...state, open: false });
  };
  const Submit = () => {
    if (menuSave.Name == "" || menuSave.Name == null) {
      handleClick("Enter report name", "warning");
    } else {
      
    setIsLoading(true);
      if (props.id == 0) {
        axios.post(APIURL + "Admin/AddMenu", menuSave).then((response) => {
          if (response.status === 200) {
            handleClick("added successfully", "success");
            goToBack("reports");
          }
        });
      } else {
        menuSave.id = props.id;
        axios.post(APIURL + "Admin/UpdateMenu", menuSave).then((response) => {
          if (response.status === 200) {
            handleClick("updated successfully", "success");
            goToBack("reports");
          }
        });
      }      
    setIsLoading(false);
    }
  };

  return isLoading ? (
    <div>
      <img alt="" className="loader-css"
                     src={LoaderGIF}
                     loading="lazy" 
                    /> 
    </div>
   ) : (
    <div className="h-admin">
      <div className="m-5">
        <div className="card-admin-div p-3">
          <h4 className="color-000">            
          {props.id == 0 ? '':'Edit Report'}
            <button
              type="submit"
              className="btn  btn-submit float-right"
              onClick={() => {
                goToBack("reports");
              }}
            >
              List
            </button>
          </h4>
          <div className="mt-5">
            <Grid container spacing={2}>
              <Grid item xs={6}>
                <TextField
                  required
                  id="outlined-required"
                  label="Report Name"
                  className="w-75 Admin-textBox"
                  value={menuSave.Name}
                  onChange={(e) => changeMenu(e, "Name")}
                />
              </Grid>
              <Grid item xs={6}></Grid>
              <Grid item xs={6} className="mt-4">
                <button
                  type="cancel"
                  className="btn btn-error"
                  onClick={() => {
                    goToBack("reports");
                  }}
                >
                  Cancel
                </button>
                <button
                  type="submit"
                  onClick={Submit}
                  className="btn  btn-submit ml-3"
                >
                  Save
                </button>
              </Grid>
            </Grid>
          </div>
        </div>
      </div>
      <Snackbar
        open={state.open}
        autoHideDuration={3000}
        onClose={handleClose}
        anchorOrigin={{
          vertical: state.vertical,
          horizontal: state.horizontal,
        }}
      >
        <Alert
          onClose={handleClose}
          severity={state.alertType}
          sx={{ width: "100%" }}
        >
          {state.alertMessage}
        </Alert>
      </Snackbar>
    </div>
  );
};
export default MenuAdd;
