import React, { useEffect, useState } from "react";
import "font-awesome/css/font-awesome.min.css";
import UserSideDrawer from "./sidebarUser";
import Home from "./home";
import { useHistory, useLocation } from "react-router-dom";
const Dashboard = () => {
  // const navigate = useHistory();
  const location = useLocation();
  // const [props, setProps] = useState(location.state);
  return (
    <div>
      <UserSideDrawer /> 
      <Home/>    
    </div>
  );
};
export default Dashboard;
