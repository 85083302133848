import React , { useState }from "react";
import Grid from "@mui/material/Grid";
import { useHistory, useLocation } from "react-router-dom";

const Home = () => {
  const navigate = useHistory();
  const location = useLocation();
  const [props, setProps] = useState(location.state);
  
const pages = [
  {
    name: "Shipment Details Report",
    url: "shipmentdetailsreport",
    style: "bgcolor-5C870E",
  },
  {
    name: "Chain Alytics Report",
    url: "chainalyticreport",
    style: "bgcolor-4F4F4F",
  },
  {
    name: "Turvo Invoice Report",
    url: "turvoinvoicereport",
    style: "bgcolor-4F4F4F",
  },,
  {
    name: "Good Foods Report",
    url: "goodfoodsreport",
    style: "bgcolor-4F4F4F",
  },
];
const goToPage = (e, value) => {
      navigate.push(value, props);
  };
  return (
    <div className="mt-5">
        <Grid container spacing={2}>
          {pages.map((page) => (
            <Grid item xs={3}>
              <div className={"card p-3 pt-5 pb-5 text-center " + page.style} onClick={((e) => goToPage(e, page.url))}>
                <h3>{page.name}</h3>
              </div>
            </Grid>
          ))}
        </Grid>
    </div>
  );
};
export default Home;
