import React, { useState, useEffect, useRef } from "react";
import "font-awesome/css/font-awesome.min.css";
import PropTypes from "prop-types";
import Tabs from "@mui/material/Tabs";
import Tab from "@mui/material/Tab";
import Typography from "@mui/material/Typography";
import Box from "@mui/material/Box";
import { styled } from "@mui/material/styles";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell, { tableCellClasses } from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import Paper from "@mui/material/Paper";
import axios from "axios";
import { APIURL } from "../../apiUri";
import ReactHTMLTableToExcel from "react-html-table-to-excel";
import LoaderGIF from "../../images/loader.gif";
import moment from "moment";
import Grid from "@mui/material/Grid";
import { downloadExcel } from "react-export-table-to-excel";
import { Button, MenuItem, Select, Stack } from "@mui/material";
import Pagination from "@mui/material/Pagination";
import DatePicker from "react-datepicker";

import "react-datepicker/dist/react-datepicker.css";
function TabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box sx={{ p: 3 }}>
          <Typography>{children}</Typography>
        </Box>
      )}
    </div>
  );
}

TabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.number.isRequired,
  value: PropTypes.number.isRequired,
};

const GoodFoodReport = () => {
  // const location = useLocation();
  // const [props, setProps] = useState(location.state);
  const [goodFoodList, setGoodFoodList] = useState([]);
  const [isDate, setIsDate] = useState(false);
  const [value, setValue] = React.useState(0);
  const tableRef = useRef(null);
  const date = new Date(2009, 10, 10);
  const month = date.toLocaleString("default", { month: "long" });
  const todayDate = new Date();
  const [requestedStartPickupDate, setRequestedStatePickupDate] =
    useState(new Date("11-01-2022"));
  const [requestedEndPickupDate, setRequestedEndPickupDate] =
    useState(new Date());
  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  function a11yProps(index) {
    return {
      id: `simple-tab-${index}`,
      "aria-controls": `simple-tabpanel-${index}`,
    };
  }

  const [isLoading, setIsLoading] = useState(false);
  const StyledTableCell = styled(TableCell)(({ theme }) => ({
    [`&.${tableCellClasses.head}`]: {
      backgroundColor: theme.palette.common.black,
      color: theme.palette.common.white,
    },
    [`&.${tableCellClasses.body}`]: {
      fontSize: 14,
    },
  }));

  const StyledTableRow = styled(TableRow)(({ theme }) => ({
    "&:nth-of-type(odd)": {
      backgroundColor: theme.palette.action.hover,
    },
    // hide last border
    "&:last-child td, &:last-child th": {
      border: 0,
    },
  }));

  useEffect(() => {
    setIsLoading(true);
    getData();
  }, []);
  const getData = () => {
    axios.get(APIURL + "Admin/GetGoodFoodReport").then(
      (response) => {
        response.data.forEach(element => {
    return    element.orignZip = element.orignZip + String.fromCharCode(8203);
      }); 
      response.data.forEach(element => {
        return element.destinationZip = element.destinationZip + String.fromCharCode(8203);
          });
      console.log('fdsgds',response.data);
        setGoodFoodList(response.data);
        setIsLoading(false);
      },
      (error) => {
        setIsLoading(false);
      }
    );
  };
  const changesRequestedEndPickupDate = (e) => {
    debugger
    setRequestedEndPickupDate(e);
    var startDate = requestedStartPickupDate;
    onFilter(startDate, e);
  };

  const changesRequestedStartPickupDate = (e) => {
    setRequestedStatePickupDate(e);
    var end_data = requestedEndPickupDate;
    onFilter(e, end_data);
  };
  const onFilter = (startDate, endDate) => {
    setIsLoading(true);

    var data = {
      startRequestedPickupDt: moment(new Date(startDate)).format("yyyy-MM-DD"),
      endRequestedPickupDt: moment(new Date(endDate)).format("yyyy-MM-DD"),
    };
    axios.post(APIURL + "Admin/GetGoodFoodReportDataUsingFilter", data).then(
      (response) => { 
        if (response.status === 200) {
          response.data.forEach(element => {
            return    element.orignZip = element.orignZip + String.fromCharCode(8203);
              }); 
              response.data.forEach(element => {
                return element.destinationZip = element.destinationZip + String.fromCharCode(8203);
                  });
          setGoodFoodList(response.data);
        }
        setIsLoading(false);
      },
      (error) => {
        setIsLoading(false);
      }
    );
  };

  // const [perPage, setPerPage] = useState(10);
  // const [size, setSize] = useState(perPage);
  // const [current, setCurrent] = useState(1);

  // const getPaginationData = (current, pageSize) => {
  //   // Normally you should get the data from the server
  //   return goodFoodList.slice((current - 1) * pageSize, current * pageSize);
  // };

  // const handleReportDownload =() =>{
  //   https://app.powerbi.com/reportEmbed?reportId=1e1041ce-b2a0-46d0-883a-c10b44608335&autoAuth=true&ctid=8dcaf237-a1c0-4a77-871c-e36fe00d5d29
  // }
  const handleExcelDownload = (e) => {
    downloadExcel({
      fileName: `GoodFoodReport`,
      sheet: "Records",
      tablePayload: {
        header: [
          "PO",
          "Shipment Number",
          "Status",
          "Pick Up Date",
          "Origin City, St",
          "Origin Zip",
          "Date Delivered",
          "Destination City, St",
          "Destination Zip",
          "Weight",
          "Accessorial Costs",
          "Freight Costs",
          "Total Costs",
        ],
        body: goodFoodList,
      },
    });
  };
  // const handlePageChanges = (e, newPage) => {
  //   console.log(`Page ${newPage}`);
  //   setCurrent(newPage);
  // };
  return (
    <div class="mt-report pt-1">
      <Box sx={{ borderBottom: 1, borderColor: "divider" }}>
        <Tabs value={value} onChange={handleChange} aria-label="">
          <Tab label="Details" {...a11yProps(1)} />
        </Tabs>
      </Box>
      <TabPanel value={value} index={0}>
        <div class="p-2">
          <Grid container>
            <Grid item xs={1.5}>
              <div className="date-style-div" >
                <label for="startDate" className="mb-0 label-date">
                  Start Requested Pick Up Date:
                </label><DatePicker  className="w-100" selected={requestedStartPickupDate} onChange={(date) => changesRequestedStartPickupDate(date)} />
              </div>
            </Grid>
            <Grid item xs={1.5}>
              <div className="date-style-div">
                <label for="endDate" className="mb-0 label-date">
                  End Requested Pick Up Date:
                </label><DatePicker  className="w-100" selected={requestedEndPickupDate} onChange={(date) => changesRequestedEndPickupDate(date)} />
              </div>
            </Grid>
            <Grid item xs={6} className="text-center pt-3">
              <h5 className="font-header">Good Foods Report</h5>
            </Grid>
            <Grid item xs={3} className="text-right  pt-2">
              {/* <a     className="btn  btn-submit mt-1"
        href="https://app.powerbi.com/reportEmbed?reportId=1e1041ce-b2a0-46d0-883a-c10b44608335&autoAuth=true&ctid=8dcaf237-a1c0-4a77-871c-e36fe00d5d29"
        target="_blank"
        rel="noreferrer"
      >Download</a> */}
              <Button
                filename="Hardware"
                onClick={handleExcelDownload}
                type="submit"
                className="btn  btn-submit mt-1"
              >
                Export To Excel
              </Button>
            </Grid>
          </Grid>
          {isLoading ? (
            <div>
              <img
                alt=""
                className="loader-css"
                src={LoaderGIF}
                loading="lazy"
              />
            </div>
          ) : (
            <div className="mt-1">
              <div className="table-container-div">
                <TableContainer
                  component={Paper}
                  ref={tableRef}
                  className="table-height"
                >
                  <Table
                    stickyHeader
                    sx={{ minWidth: 700 }}
                    aria-label="customized table"
                    id="table-to-xls"
                    className="table-div "
                  >
                    <TableHead>
                      <TableRow className="head-table-div">
                        <StyledTableCell
                          align="center"
                          className="th-style po-width"
                        >
                          PO
                        </StyledTableCell>
                        <StyledTableCell align="center" className="th-style">
                          Shipment Number
                        </StyledTableCell>
                        <StyledTableCell align="center" className="th-style">
                          Status
                        </StyledTableCell>
                        <StyledTableCell align="center" className="th-style">
                        Pick Up Date
                        </StyledTableCell>
                        <StyledTableCell align="center" className="th-style">
                          Origin City, St
                        </StyledTableCell>
                        <StyledTableCell align="center" className="th-style">
                          Orign Zip
                        </StyledTableCell>
                        <StyledTableCell align="center" className="th-style">
                          Date Delivered
                        </StyledTableCell>
                        <StyledTableCell align="center" className="th-style">
                          Destination City, St
                        </StyledTableCell>
                        <StyledTableCell align="center" className="th-style">
                          Destination Zip
                        </StyledTableCell>
                        <StyledTableCell align="center" className="th-style">
                          Weight
                        </StyledTableCell>
                        <StyledTableCell align="center" className="th-style">
                          Accessorial Costs
                        </StyledTableCell>
                        <StyledTableCell align="center" className="th-style">
                          Freight Costs
                        </StyledTableCell>
                        <StyledTableCell align="center" className="th-style">
                          Total Costs
                        </StyledTableCell>
                      </TableRow>
                    </TableHead>
                    <TableBody>
                      {/* getPaginationData(current, size) */}
                      {goodFoodList.map((row, index) => (
                        <StyledTableRow key={index}>
                          <StyledTableCell
                            component="th"
                            scope="row"
                            className="td-style po-width"
                          >
                            {row.po}
                          </StyledTableCell>
                          <StyledTableCell align="center" className="td-style ">
                            {row.shipmentNumber}
                          </StyledTableCell>
                          <StyledTableCell align="center" className="td-style">
                            {row.status}
                          </StyledTableCell>
                          <StyledTableCell align="center" className="td-style">
                            {row.datePickedUp}
                          </StyledTableCell>
                          <StyledTableCell align="center" className="td-style">
                            {row.originCitySt}
                          </StyledTableCell>
                          <StyledTableCell align="center" className="td-style">
                            {row.orignZip.toLocaleString()}
                          </StyledTableCell>
                          <StyledTableCell align="center" className="td-style">
                            {row.dateDelivered}
                          </StyledTableCell>
                          <StyledTableCell align="center" className="td-style">
                            {row.destinationCitySt}
                          </StyledTableCell>
                          <StyledTableCell align="center" className="td-style">
                            {row.destinationZip.toLocaleString()}
                          </StyledTableCell>
                          <StyledTableCell align="center" className="td-style">
                            {row.weight}
                          </StyledTableCell>
                          <StyledTableCell align="center" className="td-style">
                            {row.accessorialCosts}
                          </StyledTableCell>
                          <StyledTableCell align="center" className="td-style">
                            {row.freightCosts}
                          </StyledTableCell>
                          <StyledTableCell align="center" className="td-style">
                            {row.totalCosts}
                          </StyledTableCell>
                        </StyledTableRow>
                      ))}
                    </TableBody>
                  </Table>{" "}
                  {goodFoodList.length === 0 && (
                    <div className="p-5 text-center">
                      <p className="p-5">Record not Found </p>{" "}
                    </div>
                  )}
                </TableContainer>

                {/* <div className="table-filter-info">
                <div className="pagination-custom  mt-2 mt-2">
                <Grid container>
            <Grid item xs={4}></Grid>
            <Grid item xs={4} >    <div className="text-center"> <Stack spacing={2}>
                    <Pagination
                      showFirstButton
                      showLastButton
                      component="div"
                      justifyContent=""
                      count={goodFoodList.length/10}
                      page={current}
                      onChange={handlePageChanges}
                    />
                  </Stack></div></Grid>
                  <Grid item xs={4}>
                  <div className="text-right total-page-div">
                    <span>Total Records:</span>

                    {goodFoodList.length}
                  </div></Grid>
</Grid>
                  
                </div>
              </div> */}
                
                {/* <iframe
                  title="Report Section"
                  width="100%"
                  className="iframe-height"
                  src="https://app.powerbi.com/view?r=eyJrIjoiOGE2NDQzM2UtMzZlYy00NzgyLWJiYWEtMWNjNDA5ODI5OTg0IiwidCI6IjhkY2FmMjM3LWExYzAtNGE3Ny04NzFjLWUzNmZlMDBkNWQyOSJ9"
                  frameborder="0"
                  allowFullScreen="true"
                ></iframe> */}
              </div>
            </div>
          )}
        </div>
      </TabPanel>
    </div>
  );
};
export default GoodFoodReport;
