import React, { useEffect, useState } from "react";
import "font-awesome/css/font-awesome.min.css";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell, { tableCellClasses } from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import Paper from "@mui/material/Paper";
import { useHistory, useLocation } from "react-router-dom";
import EditIcon from "@mui/icons-material/Edit";
import { styled } from "@mui/material/styles";
import Divider from "@mui/material/Divider";
import Grid from "@mui/material/Grid";
import axios from "axios";
import { APIURL } from "../../apiUri";
import DeleteIcon from "@mui/icons-material/Delete";
import Snackbar from "@mui/material/Snackbar";
import MuiAlert from "@mui/material/Alert";
import LoaderGIF from "../../images/loader.gif";
const Alert = React.forwardRef(function Alert(props, ref) {
  return <MuiAlert elevation={6} ref={ref} variant="filled" {...props} />;
});

const StyledTableCell = styled(TableCell)(({ theme }) => ({
  [`&.${tableCellClasses.head}`]: {
    backgroundColor: "#1f1f1f",
    color: theme.palette.common.white,
  },
  [`&.${tableCellClasses.body}`]: {
    fontSize: 14,
  },
}));

const StyledTableRow = styled(TableRow)(({ theme }) => ({
  "&:nth-of-type(odd)": {
    backgroundColor: theme.palette.action.hover,
  },
  // hide last border
  "&:last-child td, &:last-child th": {
    border: 0,
  },
}));

const Menu = () => {
  const navigate = useHistory();
  const [menus, setMenus] = useState([]);
  const location = useLocation();
  const [props, setProps] = useState(location.state);
  const [isLoading, setIsLoading] = useState(false);

  const [state, setState] = useState({
    open: false,
    vertical: "top",
    horizontal: "center",
    alertMessage: "",
    alertType: "warning",
  });
  const handleClick = (message, type) => {
    setState({ ...state, open: true, alertMessage: message, alertType: type });
  };
  const handleClose = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }
    setState({ ...state, open: false });
  };
  const goToBack = (value) => {
    location.state.id = 0;
    navigate.push("/" + value, location.state);
  };
  const goToEdit = (id) => {
    location.state.id = id;
    navigate.push("/addreport", location.state);
  };
  const getData = () => {
    axios.get(APIURL + "Admin/GetMenu").then((response) => {
      setMenus(response.data);      
    setIsLoading(false);
    });
  };
  useEffect(() => {    
    setIsLoading(true);
    getData();
  }, []);

  const goToDelete = (id) => {
    setIsLoading(true);
    axios.post(APIURL + "Admin/DeleteMenu?Id=" + id).then((response) => {
      if (response.status === 200) {
        handleClick("deleted successfully", "success");
        getData();
      }
    });
  };
  return isLoading ? (
    <div>
      <img alt="" className="loader-css"
                     src={LoaderGIF}
                     loading="lazy" 
                    /> 
    </div>
   ) : (
    <div className="h-admin">
      <div className="m-5">
        <div className="text-right mb-3">
          <Grid container>
            <Grid item xs={2} className="text-left">
              <label className="color-000 mb-0 font-label">Reports</label>
            </Grid>
            <Grid item xs={10}></Grid>
          </Grid>
        </div>
        <Divider className="mb-3" />
        <TableContainer component={Paper}>
          <Table sx={{ minWidth: 700 }} aria-label="customized table">
            <TableHead>
              <TableRow>
                <StyledTableCell>Report</StyledTableCell>
                <StyledTableCell align="right">Action</StyledTableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {menus.map((row, index) => (
                <StyledTableRow key={row.id}>
                  <StyledTableCell component="th" scope="row">
                    {row.name}
                  </StyledTableCell>
                  <StyledTableCell align="right">
                    {/* <EditIcon
                      className="btn-icon"
                      onClick={() => {
                        goToEdit(row.id, "addreport");
                      }}
                    /> */}
                    <DeleteIcon
                      className="btn-icon ml-3"
                      onClick={() => {
                        goToDelete(row.id, "reports");
                      }}
                    />
                  </StyledTableCell>
                </StyledTableRow>
              ))}
            </TableBody>
          </Table>
        </TableContainer>
      </div>
      <Snackbar
        open={state.open}
        autoHideDuration={3000}
        onClose={handleClose}
        anchorOrigin={{
          vertical: state.vertical,
          horizontal: state.horizontal,
        }}
      >
        <Alert
          onClose={handleClose}
          severity={state.alertType}
          sx={{ width: "100%" }}
        >
          {state.alertMessage}
        </Alert>
      </Snackbar>
    </div>
  );
};
export default Menu;
