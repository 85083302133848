import React, { useState, useEffect } from "react";
import "font-awesome/css/font-awesome.min.css";
import AdminSideDrawer from "./sidebarAdmin";
import { useHistory, useLocation } from "react-router-dom";
const Admin = () => {
  const location = useLocation();
  const [props, setProps] = useState(location.state);
  return (
    <div>
      <AdminSideDrawer />     
    </div>
  );
};
export default Admin;
