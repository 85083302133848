import React, { useEffect, useState } from "react";
import "font-awesome/css/font-awesome.min.css";
import { useHistory, useLocation } from "react-router-dom";
import Grid from "@mui/material/Grid";
import TextField from "@mui/material/TextField";
import FormControlLabel from "@mui/material/FormControlLabel";
import FormGroup from "@mui/material/FormGroup";
import Switch from "@mui/material/Switch";
import axios from "axios";
import { APIURL } from "../../apiUri";
import InputLabel from "@mui/material/InputLabel";
import MenuItem from "@mui/material/MenuItem";
import FormControl from "@mui/material/FormControl";
import Select from "@mui/material/Select";
import Snackbar from "@mui/material/Snackbar";
import MuiAlert from "@mui/material/Alert";
import LoaderGIF from "../../images/loader.gif";
const Alert = React.forwardRef(function Alert(props, ref) {
  return <MuiAlert elevation={6} ref={ref} variant="filled" {...props} />;
});

const UserAdd = () => {
  const location = useLocation();
  const [props, setProps] = useState(location.state);
  const navigate = useHistory();

  const [MenuList, setMenuList] = useState([]);
  const [userData, setUserData] = useState(null);
  const [isLoading, setIsLoading] = useState(false);
  console.log("isLoading", isLoading);
  const [Menu, setMenu] = useState([]);
  const [userSave, setUserSave] = useState({
    FirstName: "",
    LastName: "",
    Email: "",
    Password: "",
    Status: true,
    IsAdmin: false,
    MenuList: "",
    CreatedAt: new Date(),
    UpdatedAt: new Date(),
  });
  console.log("userSave", userSave);
  useEffect(() => {
    setIsLoading(true);
    try {
      axios.get(APIURL + "Admin/GetMenu").then((response) => {
        setMenuList(response.data);
        setIsLoading(false);
      });
    } catch (error) {
    } finally {
      setIsLoading(false);
    }
    console.log("MenuList", MenuList);
  }, []);
  const goToBack = (value) => {
    location.state.id = 0;
    navigate.push(value, location.state);
  };

  const changeUser = (e, value) => {
    console.log('e, value', e, value)
    if (value === "MenuList") {
      setMenu(
        // On autofill we get a stringified value.
        typeof e.target.value === "string"
          ? e.target.value.split(",")
          : e.target.value
      );
    }
    if (value === "IsAdmin") {
      if (e.target.value == "on") {
        setUserSave({ ...userSave, [value]: !userSave.IsAdmin});
      } 
    }else if (value === "MenuList") {
      setUserSave({ ...userSave, [value]: Menu.toString() });
    } else {
      setUserSave({ ...userSave, [value]: e.target.value });
    }
  };

  useEffect(() => {
    if (props.id != 0) {
      setIsLoading(true);
      try {
        axios.get(APIURL + "Admin/GetMenu").then((response) => {
          setMenuList(response.data);
          setIsLoading(false);
        });
        axios
          .get(APIURL + "Admin/GetUserById?Id=" + props.id)
          .then((response) => {
            setUserData(response.data[0]);
            var menu = [];
            var data = response.data[0].menuList.split(",");
            data.forEach((element) => {
              menu.push(Number(element));
            });
            setMenu(menu);
          });
        setIsLoading(false);
        console.log("userdaata", userData);
      } catch (error) {
        setIsLoading(false);
      }
    }
  }, [props.id]);

  useEffect(() => {
    if (userData != null) {
      setUserSave({
        ...userSave,
        FirstName: userData.firstName,
        LastName: userData.lastName,
        Email: userData.email,
        Status: userData.status,
        MenuList: userData.MenuList,
        IsAdmin: userData.isAdmin,
      });
    }
  }, [userData]);
  const Submit = () => {
    if (userSave.IsAdmin) {
      var data = [];
      MenuList.forEach((e) => {
        data.push(e.id);
      });
      userSave.MenuList = data.toString();
    }else{
      userSave.MenuList = Menu.toString();
    }
    if (userSave.FirstName == "" || userSave.FirstName == null) {
      handleClick("Enter first name", "warning");
    } else if (userSave.LastName == "" || userSave.LastName == null) {
      handleClick("Enter last name", "warning");
    } else if (userSave.Email == "" || userSave.Email == null) {
      handleClick("Enter email", "warning");
    } else if (
      !/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i.test(userSave.Email)
    ) {
      handleClick("Enter email in correct format", "warning");
    } else {
      if (props.id == 0) {
        userSave.Password =
          userSave.FirstName.charAt(0).toString().toUpperCase() +
          userSave.LastName.charAt(0).toString().toLowerCase() +
          "AE" +
          new Date().getDate() +
          "@" +
          new Date().getFullYear();
        axios.post(APIURL + "Admin/AddUser", userSave).then((response) => {
          if (response.status === 200) {
            handleClick("added successfully", "success");
            goToBack("user");
          }
        });
      } else {
        userSave.id = props.id;
        axios.post(APIURL + "Admin/UpdateUser", userSave).then((response) => {
          if (response.status === 200) {
            handleClick("updated successfully", "success");
            goToBack("user");
          }
        });
      }
    }
  };
  const [state, setState] = useState({
    open: false,
    vertical: "top",
    horizontal: "center",
    alertMessage: "",
    alertType: "warning",
  });
  const handleClick = (message, type) => {
    setState({ ...state, open: true, alertMessage: message, alertType: type });
  };
  const handleClose = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }
    setState({ ...state, open: false });
  };
  return isLoading ? (
    <div>
      <img alt="" className="loader-css" src={LoaderGIF} loading="lazy" />
    </div>
  ) : (
    <div className="h-admin">
      <div className="m-5">
        <div className="card-admin-div p-3">
          <h4 className="color-000">
            {props.id == 0 ? "Add User" : "Edit User"}
            <button
              type="submit"
              className="btn  btn-submit float-right"
              onClick={() => {
                goToBack("user");
              }}
            >
              List
            </button>
          </h4>
          <div className="mt-5">
            <Grid container spacing={2}>
              <Grid item xs={6}>
                <TextField
                  required
                  id="outlined-required"
                  label="First Name"
                  className="w-75 Admin-textBox"
                  value={userSave?.FirstName}
                  onChange={(e) => changeUser(e, "FirstName")}
                />
              </Grid>
              <Grid item xs={6}>
                <TextField
                  required
                  id="outlined-required"
                  label="Last Name"
                  className="w-75 Admin-textBox"
                  value={userSave.LastName}
                  onChange={(e) => changeUser(e, "LastName")}
                />
              </Grid>
              <Grid item xs={6}>
                <TextField
                  required
                  id="outlined-required"
                  label="Email"
                  className="w-75 Admin-textBox"
                  value={userSave.Email}
                  onChange={(e) => changeUser(e, "Email")}
                />
              </Grid>
              <Grid item xs={6}>
                {!userSave.IsAdmin && (
                  <FormControl className="w-75">
                    <InputLabel id="demo-simple-select-label">
                      Reports
                    </InputLabel>
                    <Select
                      labelId="demo-multiple-name-label"
                      id="demo-multiple-name"
                      className="Admin-textBox"
                      multiple
                      value={Menu}
                      onChange={(e) => changeUser(e, "MenuList")}
                    >
                      {MenuList.map((data) => (
                        <MenuItem key={data.name} value={data.id}>
                          {data.name}
                        </MenuItem>
                      ))}
                    </Select>
                  </FormControl>
                )}
              </Grid>
              <Grid item xs={12}>
                <FormGroup>
                  <FormControlLabel
                    control={
                      <Switch
                        checked={userSave.IsAdmin}
                        onClick={(e) => changeUser(e, "IsAdmin")}
                      />
                    }
                    label="Admin"
                    className="color-000"
                  />
                </FormGroup>
              </Grid>
              <Grid item xs={6} className="mt-4">
                <button
                  type="cancel"
                  className="btn btn-error"
                  onClick={() => {
                    goToBack("user");
                  }}
                >
                  Cancel
                </button>
                <button
                  type="submit"
                  onClick={Submit}
                  className="btn  btn-submit ml-3"
                >
                  Save
                </button>
              </Grid>
            </Grid>
          </div>
        </div>
      </div>
      <Snackbar
        open={state.open}
        autoHideDuration={3000}
        onClose={handleClose}
        anchorOrigin={{
          vertical: state.vertical,
          horizontal: state.horizontal,
        }}
      >
        <Alert
          onClose={handleClose}
          severity={state.alertType}
          sx={{ width: "100%" }}
        >
          {state.alertMessage}
        </Alert>
      </Snackbar>
    </div>
  );
};
export default UserAdd;
