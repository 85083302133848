import React, { useState, useEffect } from "react";
import { styled, useTheme } from "@mui/material/styles";
import Box from "@mui/material/Box";
import MuiDrawer from "@mui/material/Drawer";
import MuiAppBar from "@mui/material/AppBar";
import Toolbar from "@mui/material/Toolbar";
import List from "@mui/material/List";
import CssBaseline from "@mui/material/CssBaseline";
import Divider from "@mui/material/Divider";
import IconButton from "@mui/material/IconButton";
import MenuIcon from "@mui/icons-material/Menu";
import Paper from "@mui/material/Paper";
import Popper from "@mui/material/Popper";
import MenuList from "@mui/material/MenuList";
import ChevronLeftIcon from "@mui/icons-material/ChevronLeft";
import ChevronRightIcon from "@mui/icons-material/ChevronRight";
import logo from "../../images/header-logo-white.png";
import { useHistory, useLocation } from "react-router-dom";
import MenuItem from "@mui/material/MenuItem";
import ShipmentDetailsReport from "./shipmentdetailsreport";
import ChainAlyticsReport from "./chainalyticsreport";
import ShipmentDetailsIcon from "../../images/shipment-details-icon.png";
import ChainAnalyticIcon from "../../images/chain-analytic-icon.png";
import GoodFoodIcon from "../../images/good-food-icon.png";
import TruvoInvoiceIcon from "../../images/truvo-invoie-icon.png";
import GoodFoodReportIconPowerBI from "../../images/burger-icon.png";
import TruvoInvoiceReport from "./truvoinvoicereport";
import GoodFoodReport from "./goodfoodreport";
import Button from "@mui/material/Button";
import ClickAwayListener from "@mui/material/ClickAwayListener";

const pages = [
  { name: "Shipment Details Report", url: "/shipmentdetailsreport" },
  { name: "ChainAlytic Report", url: "/chainalyticreport" },
  { name: "Turvo Invoice Report", url: "/turvoinvoicereport" },
  { name: "Good Foods Report", url: "/goodfoodreport" },
];

const drawerWidth = 230;

const openedMixin = (theme) => ({
  width: drawerWidth,
  transition: theme.transitions.create("width", {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.enteringScreen,
  }),
  overflowX: "hidden",
});

const closedMixin = (theme) => ({
  transition: theme.transitions.create("width", {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.leavingScreen,
  }),
  overflowX: "hidden",
  width: `calc(${theme.spacing(7)} + 1px)`,
  [theme.breakpoints.up("sm")]: {
    width: `calc(${theme.spacing(8)} + 1px)`,
  },
});

const DrawerHeader = styled("div")(({ theme }) => ({
  display: "flex",
  alignItems: "center",
  justifyContent: "flex-end",
  padding: theme.spacing(0, 1),
  // necessary for content to be below app bar
  ...theme.mixins.toolbar,
}));

const AppBar = styled(MuiAppBar, {
  shouldForwardProp: (prop) => prop !== "open",
})(({ theme, open }) => ({
  zIndex: theme.zIndex.drawer + 1,
  transition: theme.transitions.create(["width", "margin"], {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.leavingScreen,
  }),
  ...(open && {
    marginLeft: drawerWidth,
    width: `calc(100% - ${drawerWidth}px)`,
    transition: theme.transitions.create(["width", "margin"], {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.enteringScreen,
    }),
  }),
}));
const Drawer = styled(MuiDrawer, {
  shouldForwardProp: (prop) => prop !== "open",
})(({ theme, open }) => ({
  width: drawerWidth,
  flexShrink: 0,
  whiteSpace: "nowrap",
  boxSizing: "border-box",
  ...(open && {
    ...openedMixin(theme),
    "& .MuiDrawer-paper": openedMixin(theme),
  }),
  ...(!open && {
    ...closedMixin(theme),
    "& .MuiDrawer-paper": closedMixin(theme),
  }),
}));

export default function UserSideDrawer() {
  const navigate = useHistory();
  const location = useLocation();
  console.log("location", location);
  const [props, setProps] = useState(location.state);
  console.log("props", props);

  const theme = useTheme();
  const [open, setOpen] = React.useState(true);
  const [openLogout, setOpenLogout] = React.useState(false);
  const [menuList, setMenuList] = useState([]);

  const [anchorElUser, setAnchorElUser] = React.useState(null);
  const handleOpenUserMenu = (event) => {
    setAnchorElUser(event.currentTarget);
  };
  // const anchorRef = React.useRef(null);
  const handleCloseUserMenu = () => {
    setAnchorElUser(null);
  };
  const goToAdmin = () => {
    const data = { id: 0, data: location.state };
    navigate.push("/admin", data);
  };
  // return focus to the button when we transitioned from !open -> open
  const prevOpen = React.useRef(open);
  useEffect(() => {
    prevOpen.current = open;
  }, [open]);
  useEffect(() => {
    setMenuList(props.menuName.split(","));
  }, props);
  const handleLogout = () => {
    localStorage.setItem("user", null);
    navigate.push("/");
  };
  const handleDrawerOpen = () => {
    setOpen(true);
  };

  const handleDrawerClose = () => {
    setOpen(false);
  };

  window.scrollTo(0, 0);
  const anchorRef = React.useRef(null);
  const handleToggle = () => {
    setOpenLogout((prevOpen) => !prevOpen);
  };
  const clickAwayHandler = () => {
    setOpenLogout(!openLogout);
  };
  console.log("window.location.pathname", window.location.pathname);
  const goToPage = (e, value) => {
    switch (value) {
      case "Shipment Details Report":
        navigate.push("/shipmentdetailsreport", props);
        break;
      case "ChainAlytic Report":
        navigate.push("/chainalyticreport", props);
        break;
      case "Turvo Invoice Report":
        navigate.push("/turvoinvoicereport", props);
        break;
      case "Good Foods Report":
        navigate.push("/goodfoodsreport", props);
        break;
      default:
        break;
    }
  };
  const MyPopper = ({ openLogout, clickAwayHandler }) => (
    <ClickAwayListener onClickAway={clickAwayHandler}>
      <Popper open={openLogout}>
        <Paper className="popper">
          <MenuList>
            <MenuItem>{props.firstName}</MenuItem>
            {props.isAdmin && <MenuItem onClick={goToAdmin}>Admin</MenuItem>}
            <MenuItem onClick={handleLogout}>Logout</MenuItem>
          </MenuList>
        </Paper>
      </Popper>
    </ClickAwayListener>
  );
  return (
    <Box className="bg-color-282c34">
      <div className="inner-div">
        <CssBaseline />
        <AppBar position="fixed" open={open}>
          <Toolbar>
            <IconButton
              color="inherit"
              aria-label="open drawer"
              onClick={handleDrawerOpen}
              edge="start"
              sx={{
                marginRight: 5,
                ...(open && { display: "none" }),
              }}
            >
              <MenuIcon className="color-fff" />
            </IconButton>
            <img
              src={logo}
              loading="lazy"
              width="200"
              onClick={(e) => goToPage(e, "/shipmentdetailsreport")}
            />
            <div className="w-100 text-right">
              <Box sx={{ flexGrow: 0 }}>
                <Button
                  ref={anchorRef}
                  id="composition-button "
                  onClick={handleToggle}
                  className="btn-avatar"
                >
                  <span className="m-auto-avatar">
                    {props.firstName.charAt(0)}
                  </span>
                  {openLogout && (
                    <MyPopper {...{ clickAwayHandler, openLogout }} />
                  )}
                </Button>
              </Box>
            </div>
          </Toolbar>
        </AppBar>
        <Drawer variant="permanent" open={open}>
          <DrawerHeader>
            <IconButton onClick={handleDrawerClose}>
              {theme.direction === "rtl" ? (
                <ChevronRightIcon className="color-fff icon-size-header" />
              ) : (
                <ChevronLeftIcon className="color-fff icon-size-header" />
              )}
            </IconButton>
          </DrawerHeader>
          <Divider />
          <List className="color-fff">
            {menuList.map((page, index) => {
              console.log("index", page);
              return (
                <p
                  key={index}
                  className={
                    open ? "pl-2 hover-icon-div" : "pl-4 hover-icon-div"
                  }
                  onClick={(e) => goToPage(e, page)}
                >
                  {page === "Shipment Details Report" && (
                    <img
                      alt=""
                      src={ShipmentDetailsIcon}
                      loading="lazy"
                      title="Shipment Details Report"
                      className={
                        !open
                          ? "home-icon-expand color-fff  icon-div"
                          : "color-fff icon-div" &&
                            location.pathname === "/shipmentdetailsreport"
                          ? "bg-selected-report color-fff icon-div"
                          : "color-fff icon-div"
                      }
                    />
                  )}
                  {page === "ChainAlytic Report" && (
                    <img
                      alt=""
                      src={ChainAnalyticIcon}
                      loading="lazy"
                      title="ChainAlytic Report"
                      className={
                        !open
                          ? "home-icon-expand color-fff  icon-div"
                          : "color-fff icon-div" &&
                            location.pathname === "/chainalyticreport"
                          ? "bg-selected-report color-fff icon-div"
                          : "color-fff icon-div"
                      }
                    />
                  )}
                  {page === "Turvo Invoice Report" && (
                    <img
                      alt=""
                      src={TruvoInvoiceIcon}
                      loading="lazy"
                      title="Truvo Invoice Report"
                      className={
                        !open
                          ? "home-icon-expand color-fff  icon-div"
                          : "color-fff icon-div" &&
                            location.pathname === "/turvoinvoicereport"
                          ? "bg-selected-report color-fff icon-div"
                          : "color-fff icon-div"
                      }
                    />
                  )}
                  {page === "Good Foods Report" && (
                    <img
                      alt=""
                      src={GoodFoodIcon}
                      loading="lazy"
                      title="Good Foods Report"
                      className={
                        !open
                          ? "home-icon-expand color-fff  icon-div"
                          : "color-fff icon-div" &&
                            location.pathname === "/goodfoodsreport"
                          ? "bg-selected-report color-fff icon-div"
                          : "color-fff icon-div"
                      }
                    />
                  )}
                  <span
                    className={
                      !open
                        ? "ml-5 font-side-sidebar"
                        : "ml-1 pt-1 icon-name-sidebar font-side-sidebar"
                    }
                  >
                    {page}
                  </span>
                </p>
              );
            })}
          </List>
          <a
            className="link-a mt-1 pl-2"
            href="https://app.powerbi.com/reportEmbed?reportId=84ab3314-078c-4c0a-b4bb-e87638e761a4&autoAuth=true&ctid=a2518c7f-798a-4d8b-af60-89131105c8b4"
            target="_blank"
            rel="noreferrer"
          >
            <img
              alt=""
              src={GoodFoodReportIconPowerBI}
              loading="lazy"
              title="Good Foods Report - Power BI"
              className={
                !open
                  ? "home-icon-expand color-fff  icon-div"
                  : "color-fff icon-div"
              }
            />
            <span
              className={
                !open
                  ? "ml-5 pl-2 font-side-sidebar"
                  : "ml-1 pt-1 icon-name-sidebar font-side-sidebar"
              }
            >
              Good Foods Report - Power BI
            </span>
          </a>
          <Divider />
        </Drawer>
        <Box component="main" sx={{ marginTop: 5, backgroundColor: "#e9e9e9" }}>
          {location.pathname === "/shipmentdetailsreport" && (
            <div className={open ? "ml-open-drawer-user" : ""}>
              <ShipmentDetailsReport />
            </div>
          )}
          {location.pathname === "/chainalyticreport" && (
            <div className={open ? "ml-open-drawer-user" : ""}>
              <ChainAlyticsReport />
            </div>
          )}{" "}
          {location.pathname === "/turvoinvoicereport" && (
            <div className={open ? "ml-open-drawer-user" : ""}>
              <TruvoInvoiceReport />
            </div>
          )}
          {location.pathname === "/goodfoodsreport" && (
            <div className={open ? "ml-open-drawer-user" : ""}>
              <GoodFoodReport />
            </div>
          )}
        </Box>
      </div>
    </Box>
  );
}
