import React from "react";
import "font-awesome/css/font-awesome.min.css";
import PropTypes from "prop-types";
import Tabs from "@mui/material/Tabs";
import Tab from "@mui/material/Tab";
import Typography from "@mui/material/Typography";
import Box from "@mui/material/Box";
function TabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box sx={{ p: 3 }}>
          <Typography>{children}</Typography>
        </Box>
      )}
    </div>
  );
}

TabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.number.isRequired,
  value: PropTypes.number.isRequired,
};

const ShipmentDetailsReport = () => {
  // const location = useLocation();
  // const [props, setProps] = useState(location.state);
  const [value, setValue] = React.useState(0);

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  function a11yProps(index) {
    return {
      id: `simple-tab-${index}`,
      "aria-controls": `simple-tabpanel-${index}`,
    };
  }
  return (
    <div class="mt-report pt-1">
      <Box sx={{ borderBottom: 1, borderColor: "divider" }}>
        <Tabs value={value} onChange={handleChange} aria-label="">
          <Tab label="Details" {...a11yProps(1)} />
          <Tab label="Summary" {...a11yProps(0)} />
        </Tabs>
      </Box>
      <TabPanel value={value} index={0}>
     <iframe
          title="Report Section"
          width="100%"
          className="iframe-height"
          src="https://app.powerbi.com/view?r=eyJrIjoiMDkwZDVhZGItY2I3Ny00NDZmLThjZDEtOThhMzU3OWFiNzM3IiwidCI6ImEyNTE4YzdmLTc5OGEtNGQ4Yi1hZjYwLTg5MTMxMTA1YzhiNCIsImMiOjN9"
          frameborder="0"
          allowFullScreen="true"
        ></iframe>
      </TabPanel>
      <TabPanel value={value} index={1}>
        <iframe
          title="Report Section"
          width="100%"
          className="iframe-height"
          src="https://app.powerbi.com/view?r=eyJrIjoiNDJmZjQzNmYtNWIyOS00MDg2LWIyMzEtYmYxZGVhYjRlOTMzIiwidCI6ImEyNTE4YzdmLTc5OGEtNGQ4Yi1hZjYwLTg5MTMxMTA1YzhiNCIsImMiOjN9"
          frameborder="0"
          allowFullScreen="true"
        ></iframe>
      </TabPanel>
    </div>
  );
};
export default ShipmentDetailsReport;
