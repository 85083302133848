import React, { useEffect, useState } from "react";
import "font-awesome/css/font-awesome.min.css";
import Grid from "@mui/material/Grid";
import Button from "@mui/material/Button";
import PeopleAltIcon from "@mui/icons-material/PeopleAlt";
import { useHistory, useLocation } from "react-router-dom";
import axios from "axios";
import { APIURL } from "../../apiUri";
import LoaderGIF from "../../images/loader.gif";
import ShipmentDetailsIcon from "../../images/shipment-details-icon.png";
import ChainAnalyticIcon from "../../images/chain-analytic-icon.png";
import GoodFoodIcon from "../../images/good-food-icon.png";
import TruvoInvoiceIcon from "../../images/truvo-invoie-icon.png";
import SummarizeIcon from "@mui/icons-material/Summarize";
const AdminDashboard = () => {
  const navigate = useHistory();
  const [showElement, setShowElement] = React.useState(true);
  const location = useLocation();
  const [props, setProps] = useState(location.state);
  const [menus, setMenus] = useState([]);
  const [users, setUsers] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  useEffect(() => {
    console.log('props',props)
    setIsLoading(true);
    getData();
    setTimeout(function () {
      setShowElement(false);
    }, 3000);
  }, []);
  const goToBack = (value) => {
    navigate.push("/" + value, location.state);
  };

  const getData = () => {
      setMenus(props.data.menuName.split(','));
    axios.get(APIURL + "Admin/GetUser").then((response) => {
      setUsers(response.data);
      setIsLoading(false);
    });
  };
  const goToReport=(data) =>{
    navigate.push("/" +  data.replace(/\s+/g,'').toLowerCase(),props.data);
  }
  return isLoading ? (
    <div>
      <img alt="" className="loader-css"
                     src={LoaderGIF}
                     loading="lazy" 
                    /> 
    </div>
   ) : (
    <div className="h-admin">
      <div className="m-5">
        <div className="text-right mb-3">
          <Grid container>  
            <Grid item xs={4}>
              <div className="card m-2">
                <Grid container spacing={2} className="p-3 text-center">
                  <Grid
                    item
                    xs={4}
                    className="mt-2"
                    onClick={() => {
                      goToBack("user");
                    }}
                  >
                    <Button
                      id="composition-button"
                      aria-controls="composition-menu"
                      className="btn-round-dashboard w-75 h-75 mt-2 "
                    >
                      {users.length}
                    </Button>
                  </Grid>
                  <Grid item xs={8}>
                    <PeopleAltIcon className="color-00153f" fontSize="large" />
                    <h4 className="color-00153f pt-3">Active Users</h4>
                  </Grid>
                </Grid>
              </div>
            </Grid>
            <Grid item xs={4} className="text-left">
              <div className="card m-2">
                <Grid container spacing={2} className="p-3 text-center">
                  <Grid
                    item
                    xs={4}
                    className="mt-2"
                    onClick={() => {
                      goToReport(menus[0]);
                    }}
                  >
                    <Button
                      id="composition-button"
                      aria-controls="composition-menu"
                      className="btn-round-dashboard w-75 h-75 mt-2 "
                    >
                      {menus.length}
                    </Button>
                  </Grid>
                  <Grid item xs={8}>
                    <SummarizeIcon className="color-00153f" fontSize="large" />
                    <h4 onClick={() => {
                      goToReport(menus[0]);
                    }} className="color-00153f pt-3">Reports</h4>
                  </Grid>
                </Grid>
              </div>
            </Grid>          
            <Grid item xs={4}>
              
            </Grid>
          </Grid>
        </div>
      </div>
    </div>
  );
};
export default AdminDashboard;
